import React, { useEffect } from 'react';
import { store } from 'redux/store';
import { setCurrentTab } from 'redux/reducers/fluxAutomaticLayoutReducer';
import { Alert } from 'react-bootstrap';
import useClassicTableData from 'hooks/Flux/Automatic/Classic/useClassicTableData';
import CentralizedSpinner from 'components/CentralizedSpinner';
import FluxAutomaticGeneralTable from 'components/Flux/GeneralTable';
import ClassicRevisionSelector from 'components/Flux/Automatic/ClassicRevisionSelector';
import './index.scss';

export default function Classic() {
  useEffect(() => {
    store.dispatch(setCurrentTab('classic'));
  });
  const { modelData, isFetching } = useClassicTableData();

  return (
    <div className="pt-4 px-5">
      <div className="d-flex flex-row justify-content-end mb-3">
        <ClassicRevisionSelector />
      </div>
      {isFetching && <CentralizedSpinner />}
      {!isFetching && !modelData && <Alert variant="light">Nenhum dado para os filtros selecionados.</Alert>}
      {!isFetching && modelData && (
        <div className="automatic-table-container">
          {Object.keys(modelData).map((system) => (
            <FluxAutomaticGeneralTable
              data={modelData[system]}
              lastColAsPercent
              tableLabel={system}
              key={system}
            />
          ))}
        </div>
      )}
    </div>
  );
}
