import React from 'react';
import ReactECharts from 'echarts-for-react';
import { Button } from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import FormModal from 'components/FormModal';
import { IndicesPrediction } from 'types/Meteorology/Weather/Area/Status/getPlumaResponse';
import useIndexPredictionDownload from 'hooks/Meteorology/Weather/Area/Status/useIndexPredictionDownload';
import './index.scss';

export default function IndexPredictionModal({
  area,
  show,
  onHide,
  indexPredictionData,
}: {
  area: string;
  show: boolean;
  onHide: () => void;
  indexPredictionData: IndicesPrediction | undefined;
}) {
  const {
    onDownloadClick,
    disabled,
  } = useIndexPredictionDownload({ data: indexPredictionData, area });

  return (
    <FormModal title="PREVISÃO DO ÍNDICE" show={show} onHide={onHide}>
      <div className="text-primary fw-bold d-flex align-items-center">
        <Button
          id="download-csv-button"
          size="sm"
          className="icon-button weather-download-button me-2"
          title="Baixar dados em CSV"
          onClick={onDownloadClick}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faDownload as IconProp} />
        </Button>
        Baixar dados em CSV
      </div>
      <ReactECharts
        className="index-prediction-chart"
        option={{
          title: {
            left: 'center',
            text: `Index Forecast ${area.toUpperCase()}`,
          },
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            bottom: 0,
            left: 0,
            data: indexPredictionData?.map((index) => index.model),
          },
          grid: {
            left: '2%',
            right: '5%',
            bottom: '15%',
            containLabel: true,
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: indexPredictionData?.[0]?.indices.map((index) => index.date),
          },
          yAxis: {
            type: 'value',
          },
          series: indexPredictionData?.map((data) => ({
            name: data.model,
            type: 'line',
            data: data.indices.map((index) => index.value),
          })),
        }}
      />
    </FormModal>
  );
}
