import React from 'react';
import {
  Form, Row, Accordion,
} from 'react-bootstrap';

import useClassicOptions from 'hooks/Flux/Automatic/Classic/useClassicOptions';
import DatePicker from 'components/DatePicker';
import Model from 'components/Flux/Automatic/ControlPanel/PastSimulations/New/Form/Model';

const labelStyle = { fontWeight: 400, color: '#585858' };

export default function ClassicControlPanel() {
  const {
    acomphDate,
    forecastDate,
    acomphDates,
    forecastDates,
    models,
    setForecastDate,
    setModel,
    setAcomphDate,
  } = useClassicOptions();

  return (
    <Form>
      <Row className="mb-3">
        <Form.Label column className="text-start" style={labelStyle}>Data da ACOMPH</Form.Label>
      </Row>
      <div className="mb-3 d-flex">
        <DatePicker
          placeholderText="Selecione uma data"
          onChange={(date: Date | null) => setAcomphDate(date || new Date())}
          wrapperClassName="w-100"
          className="form-control"
          includeDates={acomphDates}
          selected={acomphDate}
        />
      </div>
      <Row className="mb-3">
        <Form.Label column className="text-start" style={labelStyle}>Data da Simulação</Form.Label>
      </Row>
      <div className="mb-3 d-flex">
        <DatePicker
          placeholderText="Selecione uma data"
          onChange={(date: Date | null) => setForecastDate(date || new Date())}
          wrapperClassName="w-100"
          className="form-control"
          includeDates={forecastDates}
          selected={forecastDate}
        />
      </div>
      <Accordion key="past-simulation-model-version">
        <Model onChange={setModel} models={models} />
      </Accordion>
    </Form>
  );
}
