import React, { useEffect, useMemo } from 'react';
import { store } from 'redux/store';
import { setCurrentTab } from 'redux/reducers/fluxAutomaticLayoutReducer';
import {
  Alert, Row, Spinner,
} from 'react-bootstrap';
import FilterSelector from 'components/Flux/Automatic/FilterSelector';
import MLTSelector from 'components/Flux/Automatic/MLTSelector';
import ViewToggler from 'components/Flux/Automatic/Monthly/ViewToggler';
import Graph from 'components/Flux/Automatic/Monthly/Graph';
import Table from 'components/Flux/Automatic/Monthly/Table';
import ActiveFilters from 'components/Flux/Automatic/ActiveFilters';
import useViewToggler from 'utils/hooks/flux/automatic/viewToggler';
import useMonthlyData from 'utils/hooks/flux/automatic/useMonthlyData';
import usePastSimulationNames from 'utils/hooks/flux/automatic/usePastSimulationNames';
import { useSelector } from 'react-redux';
import {
  selectFluxAutomaticControlPanelMltOption,
  selectFluxAutomaticControlPanelSpatialSubdivision,
  selectFluxAutomaticControlPanelAutomaticStudyScenarios,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { buildSubdivisionParam } from 'helper/flux/automatic';
import useFluxAutomaticZipDownload from 'utils/hooks/flux/automatic/useFluxAutomaticZipDownload';

export default function Monthly() {
  const { pastSimulationNames } = usePastSimulationNames();
  const unidade = useSelector(selectFluxAutomaticControlPanelMltOption);
  const models = useSelector(selectFluxAutomaticControlPanelAutomaticStudyScenarios);
  const subdivision = useSelector(selectFluxAutomaticControlPanelSpatialSubdivision);
  const {
    showGraph, showTable, toggleGraph, toggleTable,
  } = useViewToggler();
  const { graphicReadyData, isFetching } = useMonthlyData();
  const dataIsEmpty = Object.keys(graphicReadyData).length === 0;
  const spatialSubdivisionParam = useMemo(() => buildSubdivisionParam(subdivision), [subdivision]);
  const { DownloadZipButton, DownloadConfirmationDialog } = useFluxAutomaticZipDownload({
    models,
    unidade,
    type: 'monthly',
    pastSimulationNames,
    spatialSubdivisionParam,
  });

  useEffect(() => {
    store.dispatch(setCurrentTab('monthly'));
  });

  return (
    <div className="pt-4 px-5">
      <DownloadConfirmationDialog />
      <div className="d-xl-flex flex-row">
        <div className="d-lg-flex flex-row me-auto mb-2">
          <FilterSelector />
          <div className="ms-lg-3 mt-2 mt-lg-0">
            <MLTSelector />
          </div>
        </div>
        <div className="mb-2">
          <ViewToggler
            showGraph={showGraph}
            showTable={showTable}
            toggleGraph={toggleGraph}
            toggleTable={toggleTable}
          />
        </div>
        <div className="mb-2 ms-xl-3">
          <DownloadZipButton />
        </div>
      </div>
      <div className="px-0">
        <ActiveFilters />
      </div>
      <Row>
        { isFetching && <div className="text-center py-4"><Spinner /></div> }
        { !isFetching && dataIsEmpty && <Alert variant="light">Nenhum dado para os filtros selecionados.</Alert> }
        { !isFetching && !dataIsEmpty && showGraph && <Graph data={graphicReadyData} formatString="yyyyMM" /> }
        { !isFetching && !dataIsEmpty && showTable && <Table /> }
      </Row>
    </div>
  );
}
